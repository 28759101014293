import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { Button, Close, Flex, Heading, Modal, Paragraph, SearchIcon, Tabs } from '@components';
import { useActiveFrame, useTranslation } from '@utils/hooks';
import { ADULT_BASE_FRAME_NAMES, BASE_FRAME_NAMES, FRAME_SEARCH_REG_EX, KIDS_BASE_FRAME_NAMES, LOCALE_CODES } from '@constants';
import styles from './FrameShapeModalContent.module.scss';

const FrameShapeOption = dynamic(() => import('./FrameShapeOption'));

type SortedFrames = {
	adults: Array<(typeof BASE_FRAME_NAMES)[number]>;
	kids: Array<(typeof BASE_FRAME_NAMES)[number]>;
};

type FrameShapeModalContentProps = {
	callback: (f: (typeof BASE_FRAME_NAMES)[number]) => void;
	buttonProps?: Parameters<typeof Button>[0];
	available?: boolean;
	variantsAvailable?: Set<string>;
	isPopupFrameSizing?: boolean;
	onOpenChange?: (open: boolean) => void;
	open?: boolean;
};

const getTranslatedTexts = translator => {
	return {
		personalizedShoppingExperience: translator('personalized-shopping-experience'),
		looksLikeShoppingTops: translator('looks-like-shopping-tops'),
		selectBaseFrame: translator('select-base-frame'),
		justBrowsing: translator('just-browsing'),
		framesAdults: translator('frames-adults'),
		framesKids: translator('frames-kids'),
		continue: translator('continue'),
	};
};

const FrameShapeModalContent = ({
	isPopupFrameSizing = false,
	callback,
	available = true,
	variantsAvailable = new Set(BASE_FRAME_NAMES),
	buttonProps,
	open,
	onOpenChange,
}: FrameShapeModalContentProps) => {
	const { locale } = useRouter();
	const [activeFrame, setActiveFrame] = useActiveFrame();
	const isGB = locale === LOCALE_CODES.GB;
	const tabStyles = cn(styles['tab-content'], { [styles['tab-content--use-padding']]: isPopupFrameSizing });
	function sortByColumns(arr: string[], columns: number) {
		const rows = Math.ceil(arr.length / columns);
		const sorted = Array(arr.length);

		for (let i = 0; i < arr.length; i++) {
			const row = i % rows;
			const col = Math.floor(i / rows);
			sorted[row * columns + col] = arr[i];
		}

		return sorted;
	}

	function handleFrameSearch(event: ChangeEvent<HTMLInputElement>) {
		const frames = activeTab === 'adults' ? adultFrames : kidFrames;
		const {
			target: { value },
		} = event;
		const refinedValue = value
			.trim()
			.replace(FRAME_SEARCH_REG_EX, '') // This regex excludes the word 'the' from the search
			.toLowerCase();
		const newFrames = sortByColumns([...frames.filter(bf => bf.toLowerCase().includes(refinedValue))], 2);
		setSortedFrames(prevState => ({ ...prevState, [activeTab]: newFrames }));
	}

	const kidsFrameWithOutAdultFrames = KIDS_BASE_FRAME_NAMES.filter(
		frame => !ADULT_BASE_FRAME_NAMES.includes(frame as (typeof ADULT_BASE_FRAME_NAMES)[number])
	);
	const adultFrames = isGB
		? sortByColumns([...ADULT_BASE_FRAME_NAMES, ...kidsFrameWithOutAdultFrames].sort(), 2)
		: sortByColumns([...ADULT_BASE_FRAME_NAMES].sort(), 2);
	const kidFrames = sortByColumns([...KIDS_BASE_FRAME_NAMES].sort(), 2);

	const [sortedFrames, setSortedFrames] = useState<SortedFrames>({ adults: adultFrames, kids: kidFrames });
	const label = buttonProps?.label ?? activeFrame;
	// If it's Twain or Larkin default to adults on first render, otherwise default to the correct tab - after first render the previously selected tab stays active
	const [activeTab, setActiveTab] = useState(
		!['Twain', 'Larkin'].includes(activeFrame) && (KIDS_BASE_FRAME_NAMES as unknown as string[]).includes(activeFrame)
			? 'kids'
			: 'adults'
	);
	const [storedFrame, setStoredFrame] = useState<(typeof BASE_FRAME_NAMES)[number]>(label as (typeof BASE_FRAME_NAMES)[number]);
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const getStyles = (baseFrame: string) => {
		const compareFrame = isPopupFrameSizing ? storedFrame : label;
		if (baseFrame === compareFrame) {
			return styles.selected;
		}
		if (available && variantsAvailable.has(baseFrame)) {
			return styles.option;
		}
		return styles.unavailableOption;
	};

	useEffect(() => {
		// When locale changes, reset the active tab to adults
		setActiveTab('adults');
	}, [locale]);

	useEffect(() => {
		setSortedFrames({ adults: adultFrames, kids: kidFrames });
	}, [activeTab]);

	const frameBuilder = useCallback(
		(bf, type) => {
			const frameOptions = (
				<FrameShapeOption
					key={`select-${bf}-${type}-shape`}
					frame={bf}
					onClick={isPopupFrameSizing ? setStoredFrame : callback}
					setActiveFrame={isPopupFrameSizing ? null : setActiveFrame}
					className={getStyles(bf)}
					available={available && variantsAvailable.has(bf)}
					useTranslation={translator}
				/>
			);

			if (isPopupFrameSizing) return frameOptions;

			return (
				<Modal.Close key={`select-${bf}-adult-shape`} asChild>
					{frameOptions}
				</Modal.Close>
			);
		},
		[isPopupFrameSizing, callback, available, variantsAvailable, storedFrame]
	);

	const searchInput = (
		<div className={styles.searchContainer}>
			<SearchIcon label='Search Base Frames' />
			<input type='search' className={styles.searchInput} placeholder='Search' onChange={handleFrameSearch} />
		</div>
	);

	return (
		<Modal.Content
			includeCloseButton={!isPopupFrameSizing}
			className={cn(styles.content, { [styles.noPadding]: isPopupFrameSizing })}
		>
			{isPopupFrameSizing && (
				<div className={styles.closeButton}>
					<Close
						label='Close'
						onClick={() => {
							callback(null);
							onOpenChange(open);
						}}
					/>
				</div>
			)}
			<div className={isPopupFrameSizing ? styles.inner : null}>
				<Heading tag='h5'>
					{isPopupFrameSizing ? translations.looksLikeShoppingTops : translations.selectBaseFrame}
				</Heading>
				{isPopupFrameSizing && (
					<Paragraph className={styles.copy}>{translations.personalizedShoppingExperience}</Paragraph>
				)}
				<Tabs
					value={activeTab}
					onValueChange={value => setActiveTab(value)}
					activationMode='manual'
					className={styles.tabs}
				>
					<Tabs.List>
						<Tabs.Trigger value='adults'>
							<span>{translations.framesAdults}</span>
						</Tabs.Trigger>
						{!isGB && (
							<Tabs.Trigger value='kids'>
								<span>{translations.framesKids}</span>
							</Tabs.Trigger>
						)}
					</Tabs.List>
					<Tabs.Content value='adults' removeDefaultStyles className={tabStyles}>
						{searchInput}
						<div className={styles.grid}>{sortedFrames.adults.map(bf => frameBuilder(bf, 'adult'))}</div>
					</Tabs.Content>
					{!isGB && (
						<Tabs.Content value='kids' removeDefaultStyles className={tabStyles}>
							{searchInput}
							<div className={styles.grid}>{sortedFrames.kids.map(bf => frameBuilder(bf, 'kid'))}</div>
						</Tabs.Content>
					)}
				</Tabs>
			</div>
			{isPopupFrameSizing && (
				<Flex className={styles.buttons} gap={3}>
					<Modal.Close asChild>
						<Button fullWidth color='white' onClick={() => callback(null)}>
							{translations.justBrowsing}
						</Button>
					</Modal.Close>
					<Modal.Close asChild>
						<Button fullWidth onClick={() => callback(storedFrame)}>
							{translations.continue}
						</Button>
					</Modal.Close>
				</Flex>
			)}
		</Modal.Content>
	);
};

export default FrameShapeModalContent;
