import { BASE_FRAME_NAMES } from '@constants';
import { Button, FrameShapeModalContent, Modal } from '@components';
import { useActiveFrame, useHasMounted, useTranslation } from '@utils/hooks';
import styles from './FrameShapeSelect.module.scss';

type FrameShapeSelectProps = {
	callback: (f: (typeof BASE_FRAME_NAMES)[number]) => void;
	buttonProps?: Parameters<typeof Button>[0];
	available?: boolean;
	variantsAvailable?: Set<string>;
	withChevron?: boolean;
	dataTags?: object;
	onOpenChange?: (open: boolean) => void;
	open?: boolean;
	isPopupFrameSizing?: boolean;
};

const getTranslatedTexts = translator => {
	return {
		theFrame: (frame: string) => translator('the-frame', { frame }),
		selectFrameShape: translator('select-frame-shape'),
	};
};

const FrameShapeSelect = ({
	callback,
	buttonProps,
	available = true,
	variantsAvailable = new Set(BASE_FRAME_NAMES),
	withChevron = false,
	dataTags,
	onOpenChange,
	open,
	isPopupFrameSizing = false,
}: FrameShapeSelectProps) => {
	const hasMounted = useHasMounted();
	const [activeFrame] = useActiveFrame();

	const label = buttonProps?.label ?? activeFrame;
	// If it's Twain or Larkin default to adults on first render, otherwise default to the correct tab - after first render the previously selected tab stays active
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<Modal open={open} onOpenChange={onOpenChange}>
			{!isPopupFrameSizing && (
				<Modal.Trigger asChild>
					<Button
						extraClasses={styles.frameButton}
						color='white'
						{...buttonProps}
						withChevron={withChevron}
						{...dataTags}
					>
						{hasMounted ? translations.theFrame(label) : translations.selectFrameShape}
					</Button>
				</Modal.Trigger>
			)}
			<FrameShapeModalContent
				callback={callback}
				available={available}
				variantsAvailable={variantsAvailable}
				isPopupFrameSizing={isPopupFrameSizing}
				buttonProps={buttonProps}
				open={open}
				onOpenChange={onOpenChange}
			/>
		</Modal>
	);
};

export default FrameShapeSelect;
